<template>
  <div class="content Bainfg" style="min-height: 100vh; background: ">
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main">
      <div class="node">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div class="left" style="width:50%;">
            <div class="level">{{ $t("personal.grade") }}</div>
            <div class="glocse">
              <span>ID:{{ userInfo.userid }}</span>
              <van-image
                width="18px"
                height="18px"
                v-clipboard:copy="userInfo.userid"
                v-clipboard:success="firstCopySuccess"
                v-clipboard:error="firstCopyError"
                style="vertical-align: middle; margin-left: 5px"
                :src="require('@/assets/xxImage/index/fuzhi.png')"
              />
            </div>
            <div class="global" v-if="userInfo.active == 0 && userInfo.vip == 0">
              {{ $t("personal.Unactivated") }}
            </div>
            <div class="global" v-if="userInfo.active == 2 || userInfo.vip == 2">
              {{ $t("personal.Unactivateds") }}
            </div>
            <!-- <div class="global" v-if="userInfo.active == 1">
              ID:{{ userInfo.userid }}
            </div> -->
          </div>
          <div class="right" style="width:50%;text-align: center;">
            <van-image
              class="node-p"
              width="120px"
              height="90px"
              style="vertical-align: middle;margin-top:18px;"
              v-if="userInfo.active == 1 || userInfo.vip == 1"
              :src="require('@/assets/xxImage/index/' + (userInfo.vip == 1 ? 'vip-logo-active.png' : (userInfo.active == 1 ? 'vip-logo-inactive.png' : '')))"
            />
          </div>
        </div>
        <div class="btn">
          <van-button
            round
            type="info"
            color="#61bfbf"
            v-if="userInfo.active != 1 && userInfo.vip != 2"
            @click="torights"
            size="small"
            style="margin-right: 10px"
            ><span style="color: black">{{
              $t("personal.activateNow")
            }}</span></van-button
          >
          <van-button
            round
            type="info"
            color="#FFD035"
            size="small"
            v-if="userInfo.vip != 1 && userInfo.active != 2"
            style="margin-right: 10px"
            @click="showVipPopup"
            ><span style="color: black">{{ $t("personal.vipActivation") }}</span></van-button
          >
          <van-button
            round
            type="info"
            color="#8f93fb"
            size="small"
            @click="toinvite"
            >{{ $t("personal.share") }}</van-button
          >
        </div>
        <!-- <van-image
          class="node-p"
          width="86px"
          height="86px"
          :src="require('@/assets/cls/node-p-bg.png')"
        /> -->
        <!-- <div class="word">P{{ userInfo.lv }}</div> -->
      </div>
      <div class="people-numb">
        <div style="text-align: left">
          <div>{{ $t("personal.Number") }}</div>
          <div>{{ userInfo.teams }}</div>
        </div>
        <div style="text-align: right">
          <div>{{ $t("personal.shareNumber") }}</div>
          <div>{{ userInfo.directPush }}</div>
        </div>
      </div>
      <p style="margin-top: 14px; color: white">{{ $t("personal.income") }}</p>
      <div class="earnings" style="flex-wrap: wrap; height: 135px">
        <div style="text-align: left">
          <div>{{ $t("personal.Community") }}</div>
          <div>${{ userInfo.totalResult }}</div>
        </div>
        <div style="text-align: right">
          <div>{{ $t("personal.CommunityT") }}</div>
          <div>${{ Number(userInfo.staticTotal).toFixed(2) }}</div>
        </div>
        <div>
          <div>{{ $t("personal.MakingReturns") }}</div>
          <div>${{ userInfo.inviteIncome }}</div>
        </div>
        <!-- <div>
            <div>{{ $t("personal.LinkTevenue") }}</div>
            <div>{{ userInfo.inviteIncome }} USDT</div>
          </div> -->
      </div>
      <div @click="toActivities" class="record">
        {{ $t("personal.Activities") }}
      </div>
    </div>
    <div v-if="showActive" class="modelActive"></div>
    <div v-if="showActive" class="activation">
      <p class="title">{{ $t("personal.show") }}</p>
      <div class="titText">
        <p>
          {{ $t("personal.PayOrnot") }}
          <svg-icon icon-class="bi" style="font-size: 15px"></svg-icon>
          {{ kouUna }}UNA
        </p>
        <p>{{ $t("personal.asWell") }}</p>
        <p>
          <van-image
            class="node-p"
            width="16px"
            height="16px"
            style="vertical-align: middle"
            :src="require('@/assets/xxImage/index/binm.png')"
          />
          {{ activation_num }}USDT {{ $t("personal.jhInfo") }}
        </p>
      </div>
      <div class="Equity">
        <p class="EquityTile">{{ $t("personal.account") }}</p>
        <div class="EquityBox">
          <div>
            <van-image
              class="img"
              :src="require('@/assets/xxImage/index/gerentu2.png')"
              width="49px"
              height="49px"
              fit="contain"
            >
            </van-image>
            <p class="text">
              {{ $t("personal.Participate") }}<br />
              {{ $t("personal.Obtaining") }}
            </p>
          </div>
          <div class="">
            <van-image
              class="img"
              :src="require('@/assets/xxImage/index/gerentu1.png')"
              width="49px"
              height="49px"
              fit="contain"
            >
            </van-image>
            <p class="text">
              {{ $t("personal.Participation") }}<br />
              {{ $t("personal.incomes") }}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <van-button
          size="small"
          color="white"
          class="bold"
          @click="showActive = false"
          round
          ><span style="color: black">{{
            $t("index.confirmButtonText")
          }}</span></van-button
        >
        <van-button
          size="small"
          color="#8f93fb"
          class="bold"
          @click="clickConfirm(1)"
          round
        >
          <span style="color: white">{{ $t("index.confirmButtonTextY") }}</span>
        </van-button>
      </div>
    </div>

    <div v-if="showVipActivation" class="modelActive"></div>
    <div v-if="showVipActivation" class="activation">
      <p class="title">{{ $t("personal.show") }}</p>
      <div class="titText">
        <p>
          {{ $t("personal.PayOrnot") }}
          <svg-icon icon-class="bi" style="font-size: 15px"></svg-icon>
          {{ vip_activation_fee_coin }}UNA
        </p>
        <p>{{ $t("personal.asWell") }}</p>
        <p>
          <van-image
            class="node-p"
            width="16px"
            height="16px"
            style="vertical-align: middle"
            :src="require('@/assets/xxImage/index/binm.png')"
          />
          {{ vip_activation_fee_usdt }}USDT {{ $t("personal.vipInfo") }}
        </p>
      </div>
      <div class="Equity">
        <p class="EquityTile">{{ $t("personal.vipTitle") }}</p>
        <div class="EquityBox" style="justify-content: center;">
          <div>
            <van-image
              class="img"
              :src="require('@/assets/xxImage/index/vip@2x.png')"
              width="72px"
              height="47px"
              fit="contain"
            >
            </van-image>
            <p class="text">
              {{ $t("personal.vipCaption") }}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <van-button
          size="small"
          color="white"
          class="bold"
          @click="showVipActivation = false"
          round
          ><span style="color: black">{{
            $t("index.confirmButtonText")
          }}</span></van-button
        >
        <van-button
          size="small"
          color="#8f93fb"
          class="bold"
          @click="clickConfirm(2)"
          round
        >
          <span style="color: white">{{ $t("index.confirmButtonTextY") }}</span>
        </van-button>
      </div>
    </div>

    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
// import VConsole from "../vconsole.js";
import { ethers } from "ethers";

import asidePopup from "@/components/common/asidePopup";
import abi_U from "@/common/abi/abi_U.json";
import abi_C from "../common/abi/abi_C.json";
import abi_G from "../common/abi/abi_G.json";
import abi_J from "../common/abi/abi_J.json";

import BigNumbers from "bignumber.js";
export default {
  components: { asidePopup },
  inject: ["reload"],
  data() {
    return {
      asideFlag: false,
      userInfo: {},
      showActive: false,
      showVipActivation: false,
      decimals: null,
      activation_num: null,
      // line: null,
      // line_vip: null,
      balance: null,
      switchMT: null,
      activation_contract: null,
      activation_contract_vip: null,
      vip_activation_fee_usdt: null,
      vip_activation_fee_coin: null,
      kouUna: null,
      shouquanT: null,
      processingActivation: false,
      web3Utils: null,
      activationParams: []
    };
  },
  computed: {
    balances() {
      if (this.decimals) {
        let balance = BigNumbers(this.balance)
          .div(Math.pow(10, this.decimals))
          .toFixed();
        return balance;
      }
    },
  },
  methods: {
    firstCopySuccess() {
      this.$toast(
        this.$storage.get("lang") == "zh"
          ? "複製成功！"
          : "Successful replication！"
      );
    },
    firstCopyError() {
      this.$toast(
        this.$storage.get("lang") == "zh" ? "複製失敗！" : "Successful Fail"
      );
    },
    getTrustWalletFromWindow() {
      const isTrustWallet = (ethereum) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;
        return trustWallet;
      };

      const injectedProviderExist =
        typeof window !== "undefined" && typeof window.ethereum !== "undefined";

      if (!injectedProviderExist) {
        return null;
      }

      if (isTrustWallet(window.ethereum)) {
        return window.ethereum;
      }

      if (window.ethereum?.providers) {
        return window.ethereum.providers.find(isTrustWallet) ?? null;
      }
      return window["trustwallet"] ?? null;
    },
    //TW查询授权额度
    async getContractAllowance(contractAddress) {
      const injectedProvider = await this.getTrustWalletFromWindow();
      if (typeof injectedProvider != 'undefined' && injectedProvider != null) {
        injectedProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
        });
        const ethersProvider = new ethers.providers.Web3Provider(
          injectedProvider
        );
        const contracts = new ethers.Contract(
          this.contract_address,
          abi_U,
          ethersProvider
        );
        const con = await contracts.allowance(
          this.wallet_address,
          contractAddress
        );
        let num = ethers.BigNumber.from(con).toString();
        let result = BigNumbers(num).div(Math.pow(10, 18)).toFixed()
        // this.line = BigNumbers(num).div(Math.pow(10, 18)).toFixed();
        return Number(result);
      }
      return false;
    },

    //TW查询精度
    async getdecimals() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      if (typeof injectedProvider != 'undefined' && injectedProvider != null) {
        injectedProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
        });
        const ethersProvider = new ethers.providers.Web3Provider(
          injectedProvider
        );
        const contracts = new ethers.Contract(
          this.contract_address,
          abi_U,
          ethersProvider
        );
        const con = await contracts.balanceOf(this.wallet_address);
        this.decimals = await contracts.decimals();
        this.balance = Number(ethers.BigNumber.from(con).toString());
      }
    },
    // TW授权
    async onApproves(activationType) {
      const injectedProvider = await this.getTrustWalletFromWindow();
      // injectedProvider.request({
        // method: "wallet_switchEthereumChain",
        // params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      // });
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );

      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "loading...",
      });
      let amount = BigNumbers(10000000).times(Math.pow(10, 18)).toFixed();
      let contract = new ethers.Contract(
        this.contract_address,
        abi_U,
        ethersProvider
      );
      const signer = ethersProvider.getSigner();
      contract
        .connect(signer)
        .approve(this.activationParams[activationType - 1].contractAddress, amount)
        .then(
          async (res) => {
           const nums = this.activationParams[activationType - 1].contractAllowance;
           const sin =  setInterval(async () => {
              if (nums == this.activationParams[activationType - 1].contractAllowance) {
                this.activationParams[activationType - 1].contractAllowance = await this.getContractAllowance(this.activationParams[activationType - 1].contractAddress); //查询授权额度
              } else {
                this.$toast(this.$t("public.approveFailY"));
                clearInterval(sin)
                await this.getdecimals(); //查询余额和精度
                /*判断余额*/
                if (this.balances < this.activationParams[activationType - 1].amount) {
                  this.$toast(this.$t("property.deficiency"));
                  return;
                }
                /*最后执行扣U*/
                this.contractUsdt(activationType);
              }
            }, 1000);
          },
          (err) => {
            this.$toast(this.$t("public.approveFail"));
            this.$toast.clear();
          }
        );
    },
    //扣U合约TW
    async contractUsdt(activationType) {
      const injectedProvider = await this.getTrustWalletFromWindow(); //获取钱包对象
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)) }],
      });
      const ethersProvider = new ethers.providers.Web3Provider( //创建实例
        injectedProvider
      );
      const signer = ethersProvider.getSigner();

      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("withdraw.create"),
      });

      let that = this;
      let contract = new ethers.Contract(
        this.activationParams[activationType - 1].contractAddress,
        abi_J,
        ethersProvider
      );
      let amount = BigNumbers(this.activationParams[activationType - 1].amount)
        .times(Math.pow(10, this.decimals))
        .toFixed();
      contract
        .connect(signer)
        .activation(amount)
        .then(
          (receipt) => {
            this.processingActivation = true
            setTimeout(() => {
              if (this.processingActivation == true) {
                this.processingActivation = false
                this.reload()
              }
            }, 60000);
            
            this.$axios
              .post(this.$api.activationUsdt, {
                tx_hash: receipt.hash,
                amount: this.activationParams[activationType - 1].amount,
              })
              .then((res) => {
                this.processingActivation = false
                this.getUserInfo("ok");
              });
          },
          (err) => {
            console.error(err)
            this.$toast.clear();
            this.$toast(this.$t("public.ActivationN"));
            this.processingActivation = false
          }
        );
    },
    //查询授权额度
    // async getAppoveBlance() {
    //   let contract = new web3.eth.Contract(abi_U, this.contract_address);
    //   contract.methods
    //     .allowance(this.wallet_address, this.activation_contract)
    //     .call({}, (err, res) => {
    //       this.line = BigNumbers(res)
    //         .div(Math.pow(10, this.decimals))
    //         .toFixed();
    //       // this.pledgeFlag = true;
    //     });
    // },
    //查询精度
    // async getdecimal() {
    //   let contract = new web3.eth.Contract(abi_U, this.contract_address, {
    //     from: this.wallet_address,
    //   });
    //   // 余额
    //   contract.methods
    //     .balanceOf(this.wallet_address)
    //     .call({ from: this.wallet_address })
    //     .then((res) => {
    //       this.balance = res;
    //     });
    //   // 精度
    //   contract.methods
    //     .decimals()
    //     .call({ from: this.wallet_address }, (err, res) => {
    //       this.decimals = res;
    //     });
    // },
    // 授权
    // async onApprove() {
    //   this.$toast.loading({
    //     duration: 0, // 持续展示 toast
    //     forbidClick: true,
    //     message: "loading...",
    //   });
    //   if (!this.decimals) {
    //     this.$toast.clear();
    //     return;
    //   }
    //   let amount = BigNumbers(10000000)
    //     .times(Math.pow(10, this.decimals))
    //     .toFixed();
    //   let contract = new web3.eth.Contract(abi_U, this.contract_address);
    //   contract.methods
    //     .approve(this.activation_contract, amount)
    //     .send({ from: this.wallet_address }, (err, res) => {
    //       if (res) {
    //       } else {
    //         //授权失败
    //         this.$toast(this.$t("public.approveFail"));
    //       }
    //     })
    //     .on("receipt", (receipt) => {
    //       this.$toast(this.$t("public.approveFailY"));
    //       this.getAppoveBlance();
    //       /*判断余额*/
    //       if (this.balances < this.activation_num) {
    //         this.$toast(this.$t("property.deficiency"));
    //         return;
    //       }
    //       this.contractU();
    //     });
    // },
    //扣U合约
    // contractU() {
    //   this.$toast.loading({
    //     duration: 0, // 持续展示 toast
    //     forbidClick: true,
    //     message: this.$t("withdraw.create"),
    //   });
    //   let that = this;
    //   let contract = new web3.eth.Contract(abi_J, this.activation_contract);
    //   let amount = BigNumbers(this.activation_num)
    //     .times(Math.pow(10, this.decimals))
    //     .toFixed();
    //   contract.methods
    //     .activation(amount)
    //     .send({ from: this.wallet_address }, (err, res) => {
    //       if (res) {
    //       } else {
    //         this.$toast.clear();
    //         that.$toast(that.$t("withdraw.fail"));
    //       }
    //     })
    //     .on("receipt", (receipt) => {
    //       this.$axios
    //         .post(this.$api.activationUsdt, {
    //           tx_hash: receipt.transactionHash,
    //           amount: this.activation_num,
    //         })
    //         .then((res) => {
    //           this.showActive = false;
    //           this.$toast.clear();
    //           this.getUserInfo();
    //           that.$toast(this.$t("public.Activations"));
    //         });
    //     })
    //     .on("error", (confirmation) => {
    //       this.$toast.clear();
    //       that.$toast(this.$t("public.ActivationN"));
    //     });
    // },
    //激活用户
    // btnInfoOld() {
    //   // this.onApproves();
    //   // return
    //   if (this.userInfo.active == 0) {
    //     /*先扣UNA*/
    //     this.$axios
    //       .post(this.$api.activation, {
    //         Language: this.$storage.get("lang"),
    //         Authorization: " Bearer" + " " + this.$storage.get("devotokens"),
    //       })
    //       .then((res) => {
    //         /*判断授权额度 不够则拉起授权*/
    //         if (this.activation_num > this.line) {
    //           this.$toast(this.$t("pledge.insufficient"));
    //           setTimeout(() => {
    //             if (this.switchMT == 1) {
    //               this.onApprove();
    //             } else {
    //               this.$toast.loading({
    //                 duration: 0, // 持续展示 toast
    //                 forbidClick: true,
    //                 message: "loading...",
    //               });
    //               this.onApproves();
    //             }
    //           }, 500);
    //         } else {
    //           if (this.switchMT == 1) {
    //             /*判断余额*/
    //             if (this.balances < this.activation_num) {
    //               this.$toast(this.$t("property.deficiency"));
    //               return;
    //             }
    //             this.contractU();
    //           } else {
    //             /*判断余额*/
    //             if (this.balances < this.activation_num) {
    //               this.$toast(this.$t("property.deficiency"));
    //               return;
    //             }
    //             this.contractUsdt();
    //           }
    //         }
    //       });
    //   } else if (this.userInfo.active == 2) {
    //     /*判断授权额度 不够则拉起授权*/
    //     if (this.activation_num > this.line) {
    //       this.$toast(this.$t("pledge.insufficient"));
    //       setTimeout(() => {
    //         if (this.switchMT == 1) {
    //           this.onApprove();
    //         } else {
    //           this.onApproves();
    //         }
    //       }, 500);
    //     } else {
    //       if (this.switchMT == 1) {
    //         /*判断余额*/
    //         if (this.balances < this.activation_num) {
    //           this.$toast(this.$t("property.deficiency"));
    //           return;
    //         }
    //         this.contractU();
    //       } else {
    //         /*判断余额*/
    //         if (this.balances < this.activation_num) {
    //           this.$toast(this.$t("property.deficiency"));
    //           return;
    //         }
    //         this.contractUsdt();
    //       }
    //     }
    //   }
    // },
    clickConfirm(activationType) {
      if (activationType == 1) {
        if (this.userInfo.active == 0) {
          this.deductBoth(activationType);
        } else if (this.userInfo.active == 2) {
          this.deductUsdtOnly(activationType);
        }
      } else if (activationType == 2) {
        if (this.userInfo.vip == 0) {
          this.deductBoth(activationType);
        } else if (this.userInfo.vip == 2) {
          this.deductUsdtOnly(activationType);
        }
      }
    },
    deductBoth(activationType) {
      /* 先扣UNA / deduct UNA */
      this.$axios
        .post(this.$api.activation, {
          Language: this.$storage.get("lang"),
          Authorization: " Bearer" + " " + this.$storage.get("devotokens"),
          type: activationType
        })
        .then((res) => {
          /* 判断授权额度 不够则拉起授权 / deduct USDT */
          if (this.activationParams[activationType - 1].amount > this.activationParams[activationType - 1].contractAllowance) {
            this.$toast(this.$t("pledge.insufficient"));
            setTimeout(() => {
              // if (this.switchMT == 1) {
              //   this.onApprove();
              // } else {
                this.$toast.loading({
                  duration: 0, // 持续展示 toast
                  forbidClick: true,
                  message: "loading...",
                });
                this.onApproves(activationType);
              // }
            }, 500);
          } else {
            // if (this.switchMT == 1) {
            //   /*判断余额*/
            //   if (this.balances < amount) {
            //     this.$toast(this.$t("property.deficiency"));
            //     return;
            //   }
            //   this.contractU();
            // } else {
              /*判断余额*/
              if (this.balances < this.activationParams[activationType - 1].amount) {
                this.$toast(this.$t("property.deficiency"));
                return;
              }
              this.contractUsdt(activationType);
            // }
          }
        });
    },
    deductUsdtOnly(activationType) {
      /* 判断授权额度 不够则拉起授权 / deduct USDT */
      if (this.activationParams[activationType - 1].amount > this.activationParams[activationType - 1].contractAllowance) {
        this.$toast(this.$t("pledge.insufficient"));
        setTimeout(() => {
          // if (this.switchMT == 1) {
          //   this.onApprove();
          // } else {
            this.onApproves(activationType);
          // }
        }, 500);
      } else {
        // if (this.switchMT == 1) {
        //   /*判断余额*/
        //   if (this.balances < amount) {
        //     this.$toast(this.$t("property.deficiency"));
        //     return;
        //   }
        //   this.contractU();
        // } else {
          /*判断余额*/
          if (this.balances < this.activationParams[activationType - 1].amount) {
            this.$toast(this.$t("property.deficiency"));
            return;
          }
          this.contractUsdt(activationType);
        // }
      }
    },
    toActivities() {
      this.$router.push("/activeRecord");
    },
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    //点击激活
    torights() {
      this.showActive = true;
      // this.$router.push({ name: "rights" });
    },
    showVipPopup() {
      this.showVipActivation = true
    },
    toinvite() {
      this.$router.push({ name: "myShare" });
    },
    getUserInfo(type) {
      if (type == "ok") {
        let tx = setInterval(() => {
          this.$axios.get(this.$api.userInfo, {}).then((res) => {
            // clear toast if user activated successfully (normal and VIP)
            if ((this.userInfo.active == 2 && res.data.active == 1) || (this.userInfo.vip == 2 && res.data.vip == 1)) {
              this.$toast(this.$t("public.Activations"));
              this.showActive = false;
              this.showVipActivation = false;
              this.$toast.clear();
              clearInterval(tx);
            }
            this.userInfo = res.data;
            this.$forceUpdate();
          });
        }, 2000);
      } else {
        this.$axios.get(this.$api.userInfo, {}).then((res) => {
          this.userInfo = res.data;
          this.$forceUpdate();
        });
      }
    },
    settingHandle() {
      this.wallet_address = this.$store.state.walletAddress;
      let setting = JSON.parse(this.$store.state.setting);
      setting.forEach((item) => {
        if (item.key == "activation_fee_coin_num") {
          this.kouUna = item.value;
        }
        if (item.key == "activation_fee_usdt_contract") {
          this.contract_address = item.value;
        }
        if (item.key == "pledge_contract") {
          this.pledge_contract = item.value;
        }
        if (item.key == "activation_fee_usdt_num") {
          this.activation_num = Number(item.value);
        }
        if (item.key == "busd_contract") {
          this.busd_contrac = item.value;
        }
        if (item.key == "withdraw_contract") {
          this.withdraw_contract = item.value;
        }
        if (item.key == "activation") {
          this.activation_contract = item.value;
        }
        if (item.key == "vip_activation") {
          this.activation_contract_vip = item.value;
        }
        if (item.key == "vip_activation_fee_usdt_num") {
          this.vip_activation_fee_usdt = Number(item.value)
        }
        if (item.key == "vip_activation_fee_coin_num") {
          this.vip_activation_fee_coin = Number(item.value)
        }
      });

      this.activationParams = [
        {
          activationType: 1,
          amount: this.activation_num,
          contractAddress: this.activation_contract,
          contractAllowance: 0
        },
        {
          activationType: 2,
          amount: this.vip_activation_fee_usdt,
          contractAddress: this.activation_contract_vip,
          contractAllowance: 0
        }
      ]
    },
  },
  async created() {
    const injectedProvider = await this.getTrustWalletFromWindow();
    if (typeof injectedProvider != 'undefined' && injectedProvider != null && !injectedProvider.address) {
      this.$store.commit("setwalletAddress", "");
      this.$store.commit("setDevotokens", "");
      this.$storage.remove("devotokens");
      this.$store.commit("setLinkBsc", false);
      this.$storage.clear();
    }
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: "loading...",
    });
    setTimeout(() => {
      this.$toast.clear();
      this.getUserInfo();
      this.$store.dispatch("getSetting"); //网站配置
    }, 1500);
    this.settingHandle();
    let amount = BigNumbers(1)
      .times(Math.pow(2, 255 - 1))
      .toFixed();
  },
  async mounted() {
    this.web3Utils = new this.$Web3(window.ethereum).utils
    this.switchMT = window.localStorage.getItem("switchMT");
    const injectedProvider = await this.getTrustWalletFromWindow();
    if (typeof injectedProvider != 'undefined' && injectedProvider != null) {
      injectedProvider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: this.web3Utils.numberToHex(Number(process.env.VUE_APP_CHAIN_ID)),
          },
        ],
      });
    }
    // if (this.switchMT == 1) {
    //   this.getdecimal(); //查询精度
    //   setTimeout(() => {
    //     this.getAppoveBlance(); //查询授权余额
    //   }, 500);
    // } else if (this.switchMT == 2) {
      this.getdecimals(); //查询精度Tw
      setTimeout(async () => {
        this.activationParams[0].contractAllowance = await this.getContractAllowance(this.activationParams[0].contractAddress); //查询授权余额Tw
      }, 400);
      setTimeout(async () => {
        this.activationParams[1].contractAllowance = await this.getContractAllowance(this.activationParams[1].contractAddress); //查询授权余额Tw
      }, 700);
    // }
  },
};
</script>
<style lang="scss" scoped>
.Bainfg {
  background: linear-gradient(90deg, #b79afa, #8867fe) !important;
}
.content {
  background: #ffffff;
  .main {
    padding: 14px 15px;
    .node {
      width: 100%;
      height: 165px;
      background: url("../assets/xxImage/index/bj@2x.png") no-repeat;
      background-size: 100% 100%;
      padding: 14px 0 19px;
      margin-bottom: 10px;
      box-sizing: border-box;
      position: relative;
      border-radius: 10px;
      .node-p {
        position: absolute;
        top: 11px;
        right: 23px;
        display: flex;
        align-items: center;
      }
      // .word {
      //   position: absolute;
      //   top: 43px;
      //   right: 55px;
      //   font-size: 18px;
      //   font-family: Source Han Sans CN;
      //   font-weight: 800;
      //   color: #465273;
      //   background: linear-gradient(194deg, #fdf2dc 0%, #feeaca 99.6337890625%);
      //   -webkit-background-clip: text;
      //   -webkit-text-fill-color: transparent;
      // }
      .level {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #664630;
        margin: 0 0 10px 25px;
      }
      .global {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #263152;
        margin: 0 0 10px 25px;
        white-space: nowrap;
      }
      .btn {
        margin-left: 18px;
        button {
          border: 1px solid #000 !important;
          border-radius: 50px !important;
          box-shadow: inset 0 0px 4px rgba(255, 255, 255, 1) !important;
          font-weight: bold;
          // background: linear-gradient(
          //   0deg,
          //   #61BFBF,
          //   rgba(255, 255, 255, 0.5)
          // ) !important;
          // box-shadow: 0px 1px 2px 0px rgba(95, 23, 194, 0.5),
          //   0px -1px 4px 1px #cbb0fc !important;
        }
      }
    }
    .earnings_false {
      width: 100%;
      height: 140px;
      border-bottom: 1px solid #000;
      border-radius: 20px;
      background-color: #8f93fb;
    }
    .people_fasl {
      width: 100%;
      height: 73px;
      border-bottom: 1px solid #000;
      border-radius: 20px;
      // background-color: #8f93fb;
      background: #f3f9ff;
    }
    .earnings,
    .people-numb {
      width: 100%;
      height: 75px;
      background: #f3f9ff;
      border: 1px solid #000;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 1px 1px 0px 0px #000000;
      border-radius: 10px;
      > div {
        padding: 0 30px;
        width: 50%;
        div:first-child {
          font-size: 10px;
          margin-bottom: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: black;
        }
        div:last-child {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #8867fe;
        }
      }
    }
    p {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin: 27px 0 11px;
    }
  }
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.record {
  width: 100%;
  text-align: center;
  margin-top: 177px;
  color: #f0cffe;
  font-size: 12px;
}
.modelActive {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.activation {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 261px;
  // height: 286px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  z-index: 11;
  .title {
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .titText {
    margin-top: 14px;
    width: 100%;
    text-align: center;
    font-size: 11px;
    color: #666666;
    font-family: Source Han Sans CN;
    font-weight: 500;
    p {
      margin-bottom: 7px;
    }
  }
  .Equity {
    margin-top: 14px;
    width: 90%;
    margin: 0 auto;
    // height: 128px;
    border-radius: 15px;
    background: #e5e6ff;
    padding: 15px;
    .EquityTile {
      text-align: center;
      width: 100%;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 10px;
    }
    .EquityBox {
      display: flex;
      justify-content: space-between;
      div {
        text-align: center;
        .text {
          font-size: 10px;
          font-family: Source Han Sans CN;
          color: #666666;
        }
      }
    }
  }
  .bottom_btn {
    width: 90%;
    margin: 0 auto;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 97px;
      height: 32px;
      border: 1px solid #000 !important;
      border-radius: 5px;
    }
  }
}
.glocse {
  width: 100%;
  margin: 0 0 10px 25px;
  font-size: 20px;
  font-weight: bold;
  color: #263152;
  display: flex;
  align-items: center;
}
</style>